<template>
  <div class="container">
    <van-nav-bar
        left-text="公司荣耀"
        left-arrow fixed placeholder
        @click-left="$router.back()"
        v-if="isShow"
    />
    <div class="main">
      <p class="info">
        多年来，公司坚持“产品驱动·数字增效·服务为先”的经营策略，打造“三中心三平台”新明辉供应链生态体系，融通行业商流、人流、物流等资源要素，成为国内工业安全品行
        业和临沂商城数字转型的样板，获得第六届安全生产科技成果奖、中国新型智慧城市“百佳创新案例”、央媒相信品牌力量、山东省服务业高端品牌、山东名牌、山东知名品牌
        、山东优质品牌、山东省数字供应链服标准化试点企业和临沂市五一劳动奖状等荣誉。
      </p>
                <img src="../../../static/img/glory/new/1.png" alt="" style="width: 100%">
      <img src="../../../static/img/glory/new/2.png" alt="" style="width: 100%">
    <p class="info">
      公司建有工业安全品行业最大的垂直供应链云平台-新明辉商城，上游整合来自全球11个国家、2500余家工业品知名品牌，形成9大类、185个子类、40万+工业安全产品线，经营品类涵盖安全防护、安防设备、反光材料、工业胶黏、消防器材、仪器仪表、办公用品和清洁用品等。公司是美国3M、霍尼韦尔和杜邦等世界500强企业的战略合作伙伴和国内最大的代理商，连续多年获得销售冠军。
    </p>

      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../../static/img/glory/new/3.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/4.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/5.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/6.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/7.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/8.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/9.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/10.png" alt="" style="width: 100%">
        </van-swipe-item>
        <van-swipe-item>
          <img src="../../../static/img/glory/new/11.png" alt="" style="width: 100%">
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Glory",
  data(){
    return {
      isShow : true
    }
  },
  created() {
    let i = window.history.length;
    if(i == 1){
      this.isShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  .top {
    img {
      display: block;
      width: 100%;
    }
  }

  .main {
    box-sizing: border-box;
    padding: 0 15px 30px;
    margin-top: 15px;

    .info {
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 27px;
      letter-spacing: 0px;
      color: #666666;
      text-indent: 2em;
      margin-bottom: 10px;
    }

    .glory {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        text-align: center;
        margin-bottom: 15px;

        img {
          width: 100%;
        }

        p {
          font-family: PingFang-SC-Medium;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          margin-top: 5px;
          letter-spacing: 0px;
          color: #666666;
        }
      }
    }
  }
}

::v-deep .van-nav-bar__arrow {
  font-size: 19px;
  color: #666666;
}

::v-deep .van-nav-bar__text {
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>